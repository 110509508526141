import { DarkModeIcon, LightModeIcon, SystemModeIcon } from "components";
import { useThemeMode, ThemeMode } from "lib/useDarkTheme";
import React from "react";

const ThemeSelectPopupMenu: React.FC = () => {
  const [themeMode, setThemeMode] = useThemeMode();

  return (
    <div className="dropdown dropdown-top">
      <label tabIndex={0} className="btn btn-ghost btn-circle">
        {themeMode === "dark" ? (
          <DarkModeIcon className="h-6 w-6" />
        ) : themeMode === "light" ? (
          <LightModeIcon className="h-6 w-6" />
        ) : (
          <SystemModeIcon className="h-6 w-6" />
        )}
      </label>
      <ul
        tabIndex={0}
        className="dropdown-content card text-neutral menu p-2 shadow bg-base-100"
      >
        {[
          { name: "Light", key: "light" },
          { name: "Dark", key: "dark" },
          { name: "System", key: "system" },
        ].map((mode) => (
          <li key={mode.key}>
            <button
              className={`${
                themeMode === mode.key ? "bg-accent font-semibold" : ""
              } w-full p-1 rounded`}
              onClick={() => setThemeMode(mode.key as ThemeMode)}
            >
              {mode.name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ThemeSelectPopupMenu;
