import useOnOutsideClick from "@utilityjs/use-on-outside-click";
import Divider from "components/Divider";
import { useIsDarkTheme } from "lib/useDarkTheme";
import useScreenSize from "lib/useScreenSize";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import MainMenu from "./MainMenu";
import SidebarFooter from "./sidebar-footer";
import ToggleShowSidebarButton from "./ToggleShowSidebarButton";

type Props = {
  isVisible: boolean;
  setIsVisible: (show: boolean) => void;
};

const Sidebar: React.FC<Props> = ({
  isVisible: show,
  setIsVisible: setShow,
}) => {
  const { isLgUp } = useScreenSize();
  const sidebarRef = useRef(null);
  const isDarkTheme = useIsDarkTheme();
  useOnOutsideClick(sidebarRef, () => !isLgUp && setShow(false));

  return (
    <>
      <nav
        className={twMerge(
          `transition-transform w-80 fixed top-0 left-0 h-full z-20 py-4 flex flex-col bg-base-100 text-neutral shadow-md`,
          "bg-gradient-to-t from-primary via-base-100",
          !show && "-translate-x-80"
        )}
        ref={sidebarRef}
      >
        <div className="pl-16">
          <Link to="/">
            <img
              className="h-14 w-auto"
              src={`/rms-logo${isDarkTheme ? "-dark-theme" : ""}.png`}
              alt="RateMyShift"
            />
          </Link>
        </div>
        <div className="flex-grow pt-6">
          <MainMenu />
        </div>

        <div className="p-4">
          <Divider />
          <SidebarFooter />
        </div>
      </nav>

      <ToggleShowSidebarButton
        sidebarIsVisible={show}
        setSidebarIsVisible={setShow}
      />
    </>
  );
};

export default Sidebar;
