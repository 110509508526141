import React, { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

type DividerProps = ComponentProps<"div"> & {
  direction?: "horizontal" | "vertical";
};

const Divider: React.FC<DividerProps> = ({
  direction = "horizontal",
  className,
  ...rest
}) => {
  return (
    <div
      className={twMerge("divider", `divider-${direction}`, className)}
      {...rest}
    />
  );
};

export default Divider;
