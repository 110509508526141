import React, { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

const PageContent: React.FC<ComponentProps<"main">> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <main className={twMerge("p-3 pt-20 lg:pt-3", className)} {...rest}>
      {children}
    </main>
  );
};

export default PageContent;
