import { App, Button, Form, Input, Select, Typography } from "antd";
import { trpc } from "lib/trpc";
import {
  CountryCode,
  getCountries,
  isValidPhoneNumber,
  parsePhoneNumber,
} from "libphonenumber-js";
import React from "react";

type Props = {
  onAddSuccess: () => void;
  onDismiss: () => void;
};

type FormValues = {
  countryCode: CountryCode;
  mobileNumber: string;
};

const prefixSelector = (
  <Form.Item name="countryCode" noStyle>
    <Select
      style={{ width: 70 }}
      options={getCountries().map((code) => ({ value: code, label: code }))}
    />
  </Form.Item>
);

export const EnterMobileNumberForm: React.FC<Props> = ({
  onAddSuccess,
  onDismiss,
}) => {
  const { message } = App.useApp();
  const [form] = Form.useForm<FormValues>();

  const { mutateAsync: updateMobileNumber } =
    trpc.auth.mobileNumber.update.useMutation();

  const onFinish = async (values: FormValues) => {
    try {
      const { mobileNumber, countryCode } = values;
      const phoneNumber = parsePhoneNumber(mobileNumber, countryCode);
      if (!phoneNumber) {
        return;
      }

      await updateMobileNumber(phoneNumber.format("E.164"));
      message.success("Mobile number added!");
      onAddSuccess();
    } catch (error: any) {
      message.error(error.message || "Something went wrong. Please try again.");
    }
  };

  const initialValues = {
    mobileNumber: "",
    countryCode: "GB",
  };

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      initialValues={initialValues}
      form={form}
    >
      <Typography.Title level={3}>Add your mobile number</Typography.Title>
      <Typography.Paragraph>
        Add your mobile number to give you another way to receive a codes to
        sign in.
      </Typography.Paragraph>

      <Form.Item
        name="mobileNumber"
        label="Mobile Number"
        required
        rules={[
          {
            validator: async (_, value) => {
              const { countryCode } = form.getFieldsValue();
              const isValid = isValidPhoneNumber(value, countryCode);
              if (!isValid) {
                throw new Error("Invalid phone number");
              }
            },
          },
        ]}
      >
        <Input
          addonBefore={prefixSelector}
          style={{ width: "100%" }}
          type="tel"
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          Send me a code to verify
        </Button>
      </Form.Item>

      <Form.Item>
        <Button type="link" onClick={onDismiss} style={{ width: "100%" }}>
          No thanks, maybe later
        </Button>
      </Form.Item>
    </Form>
  );
};
