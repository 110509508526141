import React, { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

type InputHelperProps = ComponentProps<"div"> & {
  isError?: boolean;
};

const InputHelper: React.FC<InputHelperProps> = ({
  isError,
  children,
  className,
  ...rest
}) => (
  <div
    className={twMerge(
      `text-xs min-h-[1.25rem] p-1`,
      isError && "text-error",
      className
    )}
    {...rest}
  >
    {children}
  </div>
);

export default InputHelper;
