import React, { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

type Props = { required?: boolean } & ComponentProps<"label">;

const InputLabel: React.FC<Props> = ({
  children,
  className,
  required,
  ...rest
}) => (
  <label
    className={twMerge(
      `block p-1 text-sm`,
      required && "after:content-['_*']",
      className
    )}
    {...rest}
  >
    {children}
  </label>
);

export default InputLabel;
