import React from "react";
import { twMerge } from "tailwind-merge";

// btn	Component	Button
// btn-primary	Modifier	Button with `primary` color
// btn-secondary	Modifier	Button with `secondary` color
// btn-accent	Modifier	Button with `accent` color
// btn-info	Modifier	Button with `info` color
// btn-success	Modifier	Button with `success` color
// btn-warning	Modifier	Button with `warning` color
// btn-error	Modifier	Button with `error` color
// btn-ghost	Modifier	Button with ghost style
// btn-link	Modifier	Button styled as a link
// btn-outline	Modifier	Transparent Button with colored border
// btn-active	Modifier	Force button to show active state
// btn-disabled	Modifier	Force button to show disabled state
// glass	Modifier	Button with a glass effect
// loading	Modifier	Shows loading spinner
// no-animation	Modifier	Disables click animation
// btn-lg	Responsive	Large button
// btn-md	Responsive	Medium button (default)
// btn-sm	Responsive	Small button
// btn-xs	Responsive	Extra small button
// btn-wide	Responsive	Wide button (more horizontal padding)
// btn-block	Responsive	Full width button
// btn-circle	Responsive	Circle button with a 1:1 ratio
// btn-square	Responsive	Square button with a 1:1 ratio

type Props = React.ComponentProps<"button"> & {
  variant?: "fill" | "outline" | "ghost" | "link";
  color?:
    | "neutral"
    | "primary"
    | "secondary"
    | "accent"
    | "info"
    | "success"
    | "warning"
    | "error";
  size?: "lg" | "md" | "sm" | "xs";
  shape?: "square" | "circle" | "block" | "wide";
  glass?: boolean;
  loading?: boolean;
  noAnimation?: boolean;
  active?: boolean;
  disabled?: boolean;
  iconStart?: React.ReactNode;
  iconEnd?: React.ReactNode;
};

const Button: React.FC<Props> = ({
  variant = "fill",
  color = "neutral",
  size = "md",
  shape = null,
  glass = false,
  loading = false,
  noAnimation = false,
  active = false,
  disabled = false,
  iconStart = null,
  iconEnd = null,
  className,
  children,
  ...rest
}) => {
  const variantClass = variant !== "fill" ? `btn-${variant}` : "";
  const colorClass = color !== "neutral" ? `btn-${color}` : "";
  const sizeClass = size !== "md" ? `btn-${size}` : "";
  const shapeClass = shape !== null ? `btn-${shape}` : "";
  const activeClass = active ? "btn-active" : "";
  const disabledClass = disabled ? "btn-disabled" : null;
  const loadingClass = loading ? "loading" : null;
  const noAnimationClass = noAnimation ? "no-animation" : null;
  const glassClass = glass ? "glass" : null;
  const iconClass = iconStart || iconEnd ? "gap-2" : null;

  return (
    <button
      className={twMerge(
        "btn",
        variantClass,
        colorClass,
        sizeClass,
        shapeClass,
        activeClass,
        disabledClass,
        loadingClass,
        noAnimationClass,
        glassClass,
        iconClass,
        className
      )}
      {...rest}
    >
      {iconStart}
      {children}
      {iconEnd}
    </button>
  );
};

export default Button;
