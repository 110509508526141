import { Typography } from "antd";
import React from "react";

export const PasswordPolicyParagraph: React.FC = () => {
  return (
    <Typography.Paragraph>
      Your password must be at least 8 characters long and contain at least one
      uppercase letter, one lowercase letter, one number and one symbol.
    </Typography.Paragraph>
  );
};
