import { useEffect, useState } from "react";

type ScreenSize = {
  isSmUp: boolean;
  isMdUp: boolean;
  isLgUp: boolean;
  isXLUp: boolean;
  is2XLUp: boolean;
  isSmDown: boolean;
  isMdDown: boolean;
  isLgDown: boolean;
  isXLDown: boolean;
};

const SCREENS = {
  SM: 640,
  MD: 768,
  LG: 1024,
  XL: 1280,
  "2XL": 1536,
};

function getScreenSize(width: number): ScreenSize {
  return {
    isSmUp: width >= SCREENS["SM"],
    isMdUp: width >= SCREENS["MD"],
    isLgUp: width >= SCREENS["LG"],
    isXLUp: width >= SCREENS["XL"],
    is2XLUp: width >= SCREENS["2XL"],
    isSmDown: width < SCREENS["MD"],
    isMdDown: width < SCREENS["LG"],
    isLgDown: width < SCREENS["XL"],
    isXLDown: width < SCREENS["2XL"],
  };
}

function useScreenSize(): ScreenSize {
  const initialScreenSize = getScreenSize(window.innerWidth);
  const [screenSize, setScreenSize] = useState<ScreenSize>(initialScreenSize);

  const handleResize = () => {
    setScreenSize(getScreenSize(window.innerWidth));
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return screenSize;
}

export default useScreenSize;
