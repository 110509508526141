import useLocalStorageState from "use-local-storage-state";

export type ThemeMode = "system" | "light" | "dark";

export function useThemeMode(): [
  themeMode: ThemeMode,
  setThemeMode: (themeMode: ThemeMode) => void
] {
  const [themeMode, setThemeMode] = useLocalStorageState<ThemeMode>(
    "theme-mode",
    { defaultValue: "system" }
  );

  if (
    themeMode === "dark" ||
    (themeMode === "system" &&
      window.matchMedia("(prefers-color-scheme: dark)").matches)
  ) {
    document.documentElement.setAttribute("data-theme", "dark");
  } else {
    document.documentElement.setAttribute("data-theme", "light");
  }

  return [themeMode, setThemeMode];
}

function getTheme(themeMode: ThemeMode): "light" | "dark" {
  if (
    themeMode === "dark" ||
    (themeMode === "system" &&
      window.matchMedia("(prefers-color-scheme: dark)").matches)
  ) {
    return "dark";
  } else {
    return "light";
  }
}

export function useIsDarkTheme(): boolean {
  const [themeMode] = useThemeMode();

  return getTheme(themeMode) === "dark";
}
