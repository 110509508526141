import { Button, Grid, Modal, theme, Typography } from "antd";
import { CloseIcon } from "components";
import React from "react";
import useLocalStorageState from "use-local-storage-state";

function useNotificationRequestDismissedAt() {
  return useLocalStorageState<number | null>("notification-request-dismissed-at", {
    defaultValue: null,
  });
}

export const RequestNotificationsBanner: React.FC = () => {

  const { token } = theme.useToken();
  const { md } = Grid.useBreakpoint();

  const [
    notificationRequestDismissedAt,
    setNotificationRequestDismissedAt
  ] = useNotificationRequestDismissedAt();

  const handleDismiss = () => {
    setNotificationRequestDismissedAt(Date.now());
  }

  const handleEnableNotifications = () => {
    // TODO: implement
  };

  // const showPrompt = notificationRequestDismissedAt === null ||
  //   Date.now() - notificationRequestDismissedAt > 1000 * 60 * 60 * 24 * 30
  const showPrompt = false; // disable for now

  const styles: { [key: string]: React.CSSProperties } = {
    banner: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      gap: token.sizeSM,
      // borderBottomColor: token.colorPrimary,
      // borderBottomWidth: "2px",
      // borderBottomStyle: "solid",
      padding: token.paddingXS,
      boxShadow: token.boxShadow,
      alignItems: "center",
      backgroundColor: token.colorBgContainer
    },
    modalContent: {
      display: "flex",
      flexDirection: "column",
      gap: token.sizeLG,
      alignItems: "center",
    },
    dismissButton: {
      position: "absolute",
      right: token.paddingXS,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }

  const promptText = (
    <Typography.Text strong>
      RateMyShift is better with notifications enabled!
    </Typography.Text>
  );

  if (md) {
    if (!showPrompt) return null;

    return (
      <div style={styles.banner}>
        {promptText}
        <Button
          type="primary"
          size="small"
          onClick={handleEnableNotifications}
        >
          Enable Notifications
        </Button>
        <Button
          style={styles.dismissButton}
          size="small"
          onClick={handleDismiss}
          icon={<CloseIcon size={6} />}
        />
      </div>
    );
  } else {
    return (
      <Modal
        open={showPrompt}
        footer={null}
        onCancel={handleDismiss}
        keyboard={false}
        maskClosable={false}
      >
        <div style={styles.modalContent}>
          {promptText}
          <Button
            type="primary"
            onClick={handleEnableNotifications}
          >
            Enable Notifications
          </Button>
        </div>
      </Modal>
    );
  }
};
