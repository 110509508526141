import React from "react";
import { Toaster as DefaultToaster, resolveValue } from "react-hot-toast";
import { Transition } from "@headlessui/react";
import { ErrorIcon, InfoIcon, SuccessIcon } from "./icons";

const Toaster: React.FC = () => {
  return (
    <DefaultToaster position="bottom-left">
      {(t) => {
        const backgroundColor =
          t.type === "success"
            ? "bg-green-500"
            : t.type === "error"
            ? "bg-red-500"
            : "bg-primary-light";

        const icon =
          t.type === "success" ? (
            <SuccessIcon className="h-6 w-6" />
          ) : t.type === "error" ? (
            <ErrorIcon className="h-6 w-6" />
          ) : (
            <InfoIcon className="h-6 w-6" />
          );

        return (
          <Transition
            show={t.visible}
            {...t.ariaProps}
            className={`${backgroundColor} text-white px-5 py-3 shadow-md rounded-md flex flex-row space-x-2 items-center`}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div>{icon}</div>
            <div>{resolveValue(t.message, t)}</div>
          </Transition>
        );
      }}
    </DefaultToaster>
  );
};

export default Toaster;
