import React, { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

const Textarea: React.FC<ComponentProps<"textarea">> = ({
  className,
  ...rest
}) => (
  <textarea
    className={twMerge("textarea textarea-primary w-full text-base", className)}
    {...rest}
  />
);

export default Textarea;
