import useCurrentUser from "lib/use-current-user";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const MainMenu: React.FC = () => {
  const location = useLocation();
  const { user } = useCurrentUser();

  const links = [
    {
      title: "Rate My Shift",
      path: "/rate-my-shift",
    },
  ];

  if (user.managerOfTeamIds.length) {
    links.push(
      {
        title: "My Team",
        path: "/team-manager",
      },
      {
        title: "Follow Ups",
        path: "/team-manager/shift-follow-up",
      }
    );
  }

  if (user.seniorManagerOfTeamIds.length) {
    links.push({
      title: "Senior Manager",
      path: "/senior-manager",
    });
  }

  if (user.isAdmin) {
    links.push({
      title: "Admin",
      path: "/admin",
    });
  }

  const matches = links.map(({ path }) => {
    let matchScore = 0;
    const pathParts = path.split("/");
    const locationParts = location.pathname.split("/");
    pathParts.forEach((part, index) => {
      if (part === locationParts[index]) {
        matchScore++;
      }
    });
    return matchScore;
  });

  const maxMatch = Math.max(...matches);
  const activeIdx = maxMatch > 0 ? matches.indexOf(maxMatch) : -1;

  return (
    <ul className="my-1">
      {links.map(({ title, path }, idx) => {
        const isActive = idx === activeIdx;
        return (
          <Link key={idx} to={path}>
            <li
              className={twMerge(
                `px-6 h-10 lg:h-9 flex flex-row justify-start items-center space-x-2`,
                "text-xl font-medium text-primary-focus",
                isActive && "underline underline-offset-2",
                `hover:underline hover:underline-offset-2 transition-all`
              )}
            >
              {title}
            </li>
          </Link>
        );
      })}
    </ul>
  );
};

export default MainMenu;
