import { Button, Form, Input, message, Typography } from "antd";
import { trpc } from "lib/trpc";
import { zodFormRule } from "lib/zod-form-rule";
import React from "react";
import { z } from "zod";
import { secondFactorCodeSchema } from "../utils";

type Props = {
  onLoginSuccess: () => void;
  onSessionExpired: () => void;
};

const formSchema = z.object({
  code: secondFactorCodeSchema,
});

const rule = zodFormRule(formSchema);

export const LoginCodeForm: React.FC<Props> = ({
  onLoginSuccess,
  onSessionExpired,
}) => {
  const { mutateAsync: submitCode } =
    trpc.auth.login.secondFactor.useMutation();

  const onFinish = async (values: z.infer<typeof formSchema>) => {
    try {
      await submitCode(values.code);
      onLoginSuccess();
    } catch (error: any) {
      if (error.message?.includes("Invalid session")) {
        onSessionExpired();
        return;
      }

      message.error(error.message || "Something went wrong. Please try again.");
    }
  };

  return (
    <Form layout="vertical" onFinish={onFinish} autoComplete="off">
      <Typography.Title level={3}>Verify</Typography.Title>
      <Typography.Paragraph>
        Enter the code we have sent to verify your identity
      </Typography.Paragraph>

      <Form.Item name="code" label="Code" rules={[rule]}>
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          Verify & log in
        </Button>
      </Form.Item>
    </Form>
  );
};
