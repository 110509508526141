import { App, Button, Form, Input, Typography } from "antd";
import { PasswordPolicyParagraph } from "components/auth";
import { trpc } from "lib/trpc";
import { zodFormRule } from "lib/zod-form-rule";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { z } from "zod";
import { passwordSchema } from "./utils";

type Props = {
  onSetPasswordSuccess: () => void;
};

const formSchema = z.object({
  password: passwordSchema,
});

const rule = zodFormRule(formSchema);

export const InvitationForm: React.FC<Props> = ({ onSetPasswordSuccess }) => {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const { email, token } = useParams<{ email: string; token: string }>();

  const { mutateAsync: setPassword } = trpc.auth.password.reset.useMutation();

  const onFinish = async (values: z.infer<typeof formSchema>) => {
    try {
      await setPassword({
        email: email!,
        token: token!,
        password: values.password,
      });

      message.success("Password set successfully");

      onSetPasswordSuccess();
    } catch (error: any) {
      let errorText = error.message;
      if (errorText.includes("Invalid request")) {
        errorText = (
          <>
            Looks like you may have already set your password.{" "}
            <Link to="/auth">Click here to login</Link>
          </>
        );
      } else if (errorText.includes("Invalid token")) {
        errorText =
          "Invalid token used - please make sure you are using the most recent invitation";
      } else if (errorText.includes("Token expired")) {
        errorText = "Token expired - please request a new invitation";
      }
      message.error(errorText || "Something went wrong. Please try again.", 15);
    }
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      initialValues={{
        password: "",
        confirmPassword: "",
      }}
    >
      <Typography.Title level={2}>Welcome</Typography.Title>
      <Typography.Paragraph>
        Please set a password for logging in
      </Typography.Paragraph>

      <PasswordPolicyParagraph />

      <Form.Item label="Password" name="password" rules={[rule]}>
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Confirm password"
        name="confirmPassword"
        rules={[
          {
            validator: async (_, value) => {
              const { password } = form.getFieldsValue();
              const isValid = password === value;
              if (!isValid) {
                throw new Error("Passwords do not match");
              }
            },
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          Set password
        </Button>
      </Form.Item>
    </Form>
  );
};
