import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpBatchLink } from "@trpc/client";
import App from "App";
import { App as AntApp, ConfigProvider, ThemeConfig, theme } from "antd";
import { Toaster } from "components";
import swrConfig from "lib/swrConfig";
import { trpc } from "lib/trpc";
import { useIsDarkTheme, useThemeMode } from "lib/useDarkTheme";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import superjson from "superjson";
import { SWRConfig } from "swr";

// css
import "antd/dist/reset.css";
import "./index.css";

// service worker
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// configure dayjs locale
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
dayjs.locale("en-gb");

import enGB from "antd/locale/en_GB";

const queryClient = new QueryClient();
const trpcClient = trpc.createClient({
  transformer: superjson,
  links: [
    httpBatchLink({
      url: `/trpc`,
      maxURLLength: 2083,
    }),
  ],
});

const RootComponent = () => {
  // call this here to set the theme in the DOM
  useThemeMode();
  const isDarkTheme = useIsDarkTheme();

  const themeConfig: ThemeConfig = {
    algorithm: isDarkTheme ? theme.darkAlgorithm : theme.defaultAlgorithm,
    token: {
      colorPrimary: "#ffbe98",
      fontSize: 16,
      fontSizeHeading1: 30,
      fontSizeHeading2: 24,
      fontSizeHeading3: 20,
      fontSizeHeading4: 18,
      fontSizeHeading5: 16,
      controlHeight: 36,
      fontFamily:
        "'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
      linkHoverDecoration: "underline",
      colorLinkHover: "#ffbe98",
      colorLinkActive: "#ffbe98",
      colorLink: "#ffbe98",
    },
  };

  return (
    <BrowserRouter>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <SWRConfig value={swrConfig}>
            <ConfigProvider theme={themeConfig} locale={enGB}>
              <AntApp>
                <App />
              </AntApp>
              <Toaster />
            </ConfigProvider>
          </SWRConfig>
        </QueryClientProvider>
      </trpc.Provider>
    </BrowserRouter>
  );
};

ReactDOM.render(<RootComponent />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
