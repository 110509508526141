import { message } from "antd";
import { LogoutIcon } from "components";
import { trpc } from "lib/trpc";
import useCurrentUser from "lib/use-current-user";
import React from "react";

const LogoutButton: React.FC = () => {
  const { refetch: refetchUser } = useCurrentUser();

  const { mutateAsync: logout } = trpc.auth.logout.useMutation();

  async function handleLogout() {
    try {
      await logout();
      message.success("Logged out successfully");
    } catch (e: any) {
      message.error(e.message || "An unknown error occurred. Please try again");
    }
    refetchUser();
  }

  return (
    <div className="tooltip" data-tip="Logout">
      <button className="btn btn-ghost btn-circle" onClick={handleLogout}>
        <LogoutIcon className="h-6 w-6" />
      </button>
    </div>
  );
};

export default LogoutButton;
