import { Card, PageContent } from "components";
import { useIsDarkTheme } from "lib/useDarkTheme";
import React from "react";

const NotFound: React.FC = () => {
  const isDarkTheme = useIsDarkTheme();

  return (
    <PageContent className="flex flex-row justify-center">
      <Card className="mt-48 flex flex-col items-center space-y-4 max-w-sm">
        <img
          className="h-14"
          src={`/rms-logo${isDarkTheme ? "-dark-theme" : ""}.png`}
          alt="RateMyShift"
        />
        <h1 className="text-xl text-center">
          Oops! The page you are looking for doesn't exist.
        </h1>
      </Card>
    </PageContent>
  );
};

export default NotFound;
