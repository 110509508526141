import React, { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

const Card: React.FC<ComponentProps<"section">> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <section className={twMerge("card bg-base-100 p-3", className)} {...rest}>
      {children}
    </section>
  );
};

export default Card;
