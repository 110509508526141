import React, { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

const Form: React.FC<ComponentProps<"form">> = ({ className, ...rest }) => (
  <form
    className={twMerge("flex flex-col space-y-2 items-start", className)}
    {...rest}
  />
);

export default Form;
