import * as yup from "yup";
import {
  AuthUserCreateOneInput,
  authUserCreateOneInputSchemaProps,
} from "./auth";
import {
  createSchema,
  createValidator,
  idSchema,
  Ordering,
  orderingSchemaProps,
} from "./utils";

export type UserCreateOneInput = AuthUserCreateOneInput & {
  memberOfTeamIds?: number[];
  managerOfTeamIds?: number[];
  seniorManagerOfTeamIds?: number[];
  analystOfTeamIds?: number[];
};

export const userCreateOneInputSchemaProps = {
  ...authUserCreateOneInputSchemaProps,
  memberOfTeamIds: yup.array().of(idSchema).default([]),
  managerOfTeamIds: yup.array().of(idSchema).default([]),
  seniorManagerOfTeamIds: yup.array().of(idSchema).default([]),
  analystOfTeamIds: yup.array().of(idSchema).default([]),
};

export const userCreateOneInputSchema = createSchema(
  userCreateOneInputSchemaProps
);

export const validateUserCreateOneInput = createValidator<UserCreateOneInput>(
  userCreateOneInputSchema
);

export const userFindOrderingSchemaProps = orderingSchemaProps(
  ["email", "firstName", "lastName", "createdAt", "lastLoginAt"],
  "email"
);

export const userFindOrderingSchema = createSchema(userFindOrderingSchemaProps);

export const validateUserFindOrdering = createValidator<Ordering>(
  userFindOrderingSchema
);


