import { trpc } from "lib/trpc";
import React from "react";
import { Link } from "react-router-dom";

type Props = {
  teamId: number;
};

export const TeamLink: React.FC<Props> = ({ teamId }) => {

  const { data: team, error } = trpc.team.byId.useQuery(teamId);

  if (!team && !error) return <>...</>;

  if (error) return <>Error fetching team</>;

  return <Link to={`/admin/teams/${teamId}`}>{team?.teamName}</Link>;
};
