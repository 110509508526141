import { RouterOutput, trpc } from "./trpc";

export type CurrentUser = NonNullable<RouterOutput["auth"]["currentUser"]>;

// always called within pages that require authentication
// so we can safely assume the user is logged in
function useCurrentUser() {
  const { data: user, refetch } = trpc.auth.currentUser.useQuery();

  return { user: user as CurrentUser, refetch };
}

export default useCurrentUser;
