import React from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  titleText: string;
  otherContent?: React.ReactNode;
} & React.ComponentProps<"div">;

const PageHeading: React.FC<Props> = ({
  titleText,
  otherContent,
  className,
  ...rest
}) => {
  return (
    <div
      className={twMerge(
        "flex flex-row justify-between items-center mb-4",
        className
      )}
      {...rest}
    >
      <h1 className="text-2xl">{titleText}</h1>
      {otherContent || null}
    </div>
  );
};

export default PageHeading;
