import axios from "axios";

const fetcher = async (url: string) => {
  const res = await axios.get(url);
  if (res.status !== 200) {
    throw new Error(res.data.message || "Failed to fetch");
  }

  return res.data;
};

const swrConfig = {
  fetcher,
};

export default swrConfig;
