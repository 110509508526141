import React from "react";
import { Tab } from "@headlessui/react";

type Props = {
  defaultTab?: string;
  tabs: {
    key: string;
    title: React.ReactNode;
    content: React.ReactNode;
  }[];
  currentTab: string;
  setCurrentTab: (key: string) => void;
  className?: string;
};

const Tabs: React.FC<Props> = ({
  defaultTab,
  currentTab,
  setCurrentTab,
  tabs,
  className,
}) => {
  const defaultIndex = tabs.findIndex((tab) => tab.key === defaultTab) || 0;
  const selectedIndex = tabs.findIndex((tab) => tab.key === currentTab) || 0;

  return (
    <div className={className}>
      <Tab.Group
        defaultIndex={defaultIndex}
        selectedIndex={selectedIndex}
        onChange={(idx) => {
          setCurrentTab(tabs[idx].key);
        }}
      >
        <Tab.List className="border-b">
          {tabs.map((tab) => {
            let className = "px-4 py-2 rounded-t-md outline-none";
            if (tab.key === currentTab)
              className += " border-primary border-b-4 bg-base-100";
            return (
              <Tab key={tab.key} className={className}>
                {tab.title}
              </Tab>
            );
          })}
        </Tab.List>
        <Tab.Panels>
          {tabs.map((tab) => (
            <Tab.Panel key={tab.key}>{tab.content}</Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default Tabs;
