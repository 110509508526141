import { App, Button, Space } from "antd";
import { EmailSendIcon } from "components/icons";
import { trpc } from "lib/trpc";
import React from "react";

type Props = {
  email: string;
};

export const ResendUserInvitationButton: React.FC<Props> = ({ email }) => {
  const { message } = App.useApp();

  const { mutateAsync: resendUserInvitation } = trpc.user.resendUserInvitation.useMutation();

  const onResendInvitation = async () => {
    try {
      await resendUserInvitation(email);
      message.success("Invitation email sent successfully");
    } catch (error: any) {
      message.error(error.message || "Something went wrong. Please try again.");
    }
  };

  return (
    <Button type="primary" size="small" onClick={onResendInvitation}>
      <Space>
        <EmailSendIcon size={0.8} />
        Resend invitation
      </Space>
    </Button>
  );
};
