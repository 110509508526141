import React from "react";
import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import { LoginCodeForm } from "./LoginCodeForm";
import { LoginEmailPasswordForm } from "./LoginEmailPasswordForm";
import { RequestCodeForm } from "./RequestCodeForm";

type Props = {
  onLoginSuccess: () => void;
  onSecondFactorSetUpRequired: () => void;
};

export const LoginForms: React.FC<Props> = ({
  onLoginSuccess,
  onSecondFactorSetUpRequired,
}) => {
  const navigate = useNavigate();

  function goToRequestCode(methods: string[]) {
    navigate("request-code", { state: { methods } });
  }

  function onCodeSendSuccess() {
    navigate("enter-code");
  }

  function goToEmailPasswordLogin() {
    navigate("password");
  }

  const element = useRoutes([
    {
      path: "password",
      element: (
        <LoginEmailPasswordForm
          onSecondFactorRequired={goToRequestCode}
          onLoginSuccess={onLoginSuccess}
        />
      ),
    },
    {
      path: "request-code",
      element: (
        <RequestCodeForm
          onCodeSendSuccess={onCodeSendSuccess}
          onNoMethodsAvailable={goToEmailPasswordLogin}
        />
      ),
    },
    {
      path: "enter-code",
      element: (
        <LoginCodeForm
          onLoginSuccess={onLoginSuccess}
          onSessionExpired={goToEmailPasswordLogin}
        />
      ),
    },
    {
      path: "*",
      element: <Navigate to="password" replace={true} />,
    },
  ]);

  return element;
};
