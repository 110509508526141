import dayjs from "dayjs";
import useLocalStorageState from "use-local-storage-state";
import { trpc } from "./trpc";

export function useShouldRequestMobileNumber() {
  const { data: user } = trpc.auth.currentUser.useQuery();
  const [requestDismissedAt, setRequestDismissedAt] = useLocalStorageState<
    string | null
  >("mobile-number-request-dismissed-at", {
    defaultValue: null,
  });

  function dismissMobileNumberRequest() {
    setRequestDismissedAt(new Date().toISOString());
  }

  // should request if have not requested before or if requested before and it
  // was more than 2 months ago
  const shouldRequestMobileNumber =
    user &&
    (user.isSecondFactorEnabled || user.isSecondFactorEnforced) &&
    (user.isMobileNumberVerified === null ||
      user.isMobileNumberVerified === false) &&
    (requestDismissedAt === null ||
      dayjs().subtract(2, "month").isAfter(new Date(requestDismissedAt)));

  return { shouldRequestMobileNumber, dismissMobileNumberRequest };
}
