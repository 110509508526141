import * as yup from "yup";
import {
  createSchema,
  createValidator,
  passwordSchema,
  userEmailSchema,
  userFirstNameSchema,
  userLastNameSchema,
} from "./utils";

export type AuthUserCreateOneInput = {
  email: string;
  firstName: string;
  lastName: string;
  isAdmin: boolean;
  password?: string;
};

export const authUserCreateOneInputSchemaProps = {
  email: userEmailSchema.required(),
  firstName: userFirstNameSchema.required(),
  lastName: userLastNameSchema.required(),
  isAdmin: yup.boolean().required(),
  password: passwordSchema,
};

export const authUserCreateOneInputSchema = createSchema(
  authUserCreateOneInputSchemaProps
);

export const validateAuthUserCreateOneInput =
  createValidator<AuthUserCreateOneInput>(authUserCreateOneInputSchema);

export type AuthUserUpdateOneInput = {
  firstName?: string;
  lastName?: string;
  isAdmin?: boolean;
  isSecondFactorEnabled?: boolean;
};

export const authUserUpdateOneInputSchemaProps = {
  firstName: userFirstNameSchema,
  lastName: userLastNameSchema,
  isAdmin: yup.boolean(),
  isSecondFactorEnabled: yup.boolean(),
};

export const authUserUpdateOneInputSchema = createSchema(
  authUserUpdateOneInputSchemaProps
);

export const validateAuthUserUpdateOneInput =
  createValidator<AuthUserUpdateOneInput>(authUserUpdateOneInputSchema);

export const totpCodeSchema = yup.string().trim().length(6);

export type AuthUpdatePasswordInput = {
  email: string;
  password: string;
  token: string;
};

export const authUpdatePasswordInputSchemaProps = {
  email: userEmailSchema.required(),
  password: passwordSchema.required(),
  token: yup.string().required(),
};

export const authUpdatePasswordInputSchema = createSchema(
  authUpdatePasswordInputSchemaProps
);

export const validateAuthUpdatePasswordInput =
  createValidator<AuthUpdatePasswordInput>(authUpdatePasswordInputSchema);

export type AuthRequestPasswordResetInput = {
  email: string;
};

export const authRequestPasswordResetInputSchemaProps = {
  email: userEmailSchema.required(),
};

export const authRequestPasswordResetInputSchema = createSchema(
  authRequestPasswordResetInputSchemaProps
);

export const validateAuthRequestPasswordResetInput =
  createValidator<AuthRequestPasswordResetInput>(
    authRequestPasswordResetInputSchema
  );

export type AuthSetUpSecondFactorInput = {
  mobileNumber: string;
};

export const authSetUpSecondFactorInputSchemaProps = {
  mobileNumber: yup.string().required(),
};

export const authSetUpSecondFactorInputSchema = createSchema(
  authSetUpSecondFactorInputSchemaProps
);

export const validateAuthSetUpSecondFactorInput =
  createValidator<AuthSetUpSecondFactorInput>(authSetUpSecondFactorInputSchema);

export type AuthVerifySecondFactorSecretInput = {
  code: string;
};

export const authVerifySecondFactorSecretInputSchemaProps = {
  code: totpCodeSchema.required(),
};

export const authVerifySecondFactorSecretInputSchema = createSchema(
  authVerifySecondFactorSecretInputSchemaProps
);

export const validateAuthVerifySecondFactorSecretInput =
  createValidator<AuthVerifySecondFactorSecretInput>(
    authVerifySecondFactorSecretInputSchema
  );
