import { CloseMenuIcon, OpenMenuIcon } from "components";
import useScreenSize from "lib/useScreenSize";
import React, { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  sidebarIsVisible: boolean;
  setSidebarIsVisible: (visible: boolean) => void;
};

const ToggleShowSidebarButton: React.FC<Props> = ({
  sidebarIsVisible,
  setSidebarIsVisible,
}) => {
  const TRIGGER_ZONE_SIZE = 60;

  const [toggleButtonIsVisible, setToggleButtonIsVisible] = useState(true);
  const [mouseInTriggerZone, setMouseInTriggerZone] = useState(false);
  const [hasScrolledDown, setHasScrolledDown] = useState(false);

  const { isMdDown } = useScreenSize();

  const handleMouseMove = (e: MouseEvent) => {
    if (
      e.x <= TRIGGER_ZONE_SIZE &&
      e.y <= TRIGGER_ZONE_SIZE &&
      !mouseInTriggerZone
    ) {
      setMouseInTriggerZone(true);
    } else if (
      (e.x > TRIGGER_ZONE_SIZE || e.y > TRIGGER_ZONE_SIZE) &&
      mouseInTriggerZone
    ) {
      setMouseInTriggerZone(false);
    } else {
      // pass
    }
  };

  let prevScrollY = window.scrollY;
  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (prevScrollY < currentScrollY && hasScrolledDown === false) {
      setHasScrolledDown(true);
    } else if (prevScrollY > currentScrollY && hasScrolledDown === true) {
      setHasScrolledDown(false);
    } else {
      // no need to change the state
    }
    prevScrollY = currentScrollY;
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("scroll", handleScroll);
    const handleScrollInterval = window.setInterval(handleScroll, 1000);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("scroll", handleScroll);
      window.clearInterval(handleScrollInterval);
    };
  });

  let hideTimeout: number;

  useEffect(() => {
    const shouldShowToggleButton =
      sidebarIsVisible || mouseInTriggerZone || (isMdDown && !hasScrolledDown);

    if (!shouldShowToggleButton && toggleButtonIsVisible) {
      hideTimeout = window.setTimeout(
        () => setToggleButtonIsVisible(false),
        1500
      );
    }

    if (shouldShowToggleButton && !toggleButtonIsVisible) {
      window.clearTimeout(hideTimeout);
      setToggleButtonIsVisible(true);
    }
  }, [
    hasScrolledDown,
    isMdDown,
    mouseInTriggerZone,
    sidebarIsVisible,
    toggleButtonIsVisible,
  ]);

  return (
    <button
      className={twMerge(
        `transition-transform fixed top-5 left-3 z-40 p-2 rounded-md text-2xl text-center text-white bg-primary hover:bg-primary-light`,
        !toggleButtonIsVisible && "-translate-x-80",
        !sidebarIsVisible && "opacity-75"
      )}
      onClick={(e) => {
        e.stopPropagation();
        setSidebarIsVisible(!sidebarIsVisible);
      }}
    >
      {sidebarIsVisible ? (
        <CloseMenuIcon className="h-7 w-7" />
      ) : (
        <OpenMenuIcon className="h-7 w-7" />
      )}
    </button>
  );
};

export default ToggleShowSidebarButton;
