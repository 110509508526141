import * as yup from "yup";
import {
  createSchema,
  createValidator,
  Ordering,
  orderingSchemaProps,
  Pagination,
} from "./utils";

export type OrganisationalIssue = {
  issueName: string;
  issueDescription: string;
  isArchived: boolean;
};

export const organisationalIssueIssueNameSchema = yup
  .string()
  .trim()
  .min(1)
  .max(20);
export const organisationalIssueIssueDescriptionSchema = yup
  .string()
  .trim()
  .min(1)
  .max(50);

export type OrganisationalIssueFilter = {
  isArchived?: boolean;
};

export const organisationalIssueFilterSchemaProps = {
  isArchived: yup.boolean(),
};

export const organisationalIssueFilterSchema = createSchema(
  organisationalIssueFilterSchemaProps
);

export const validateOrganisationalIssueFilter =
  createValidator<OrganisationalIssueFilter>(organisationalIssueFilterSchema);

export const organisationalIssueFindOrderingSchemaProps = orderingSchemaProps(
  ["issueName", "isArchived"],
  "issueName"
);

export const organisationalIssueFindOrderingSchema = createSchema(
  organisationalIssueFindOrderingSchemaProps
);

export const validateOrganisationalIssueFindOrdering =
  createValidator<Ordering>(organisationalIssueFindOrderingSchema);

export type OrganisationalIssueFindInput = OrganisationalIssueFilter &
  Pagination &
  Ordering;

export type OrganisationalIssueCreateOneInput = {
  issueName: string;
  issueDescription: string;
};

export const organisationalIssueCreateOneInputSchemaProps = {
  issueName: organisationalIssueIssueNameSchema.required(),
  issueDescription: organisationalIssueIssueDescriptionSchema.required(),
};

export const organisationalIssueCreateOneInputSchema = createSchema(
  organisationalIssueCreateOneInputSchemaProps
);

export const validateOrganisationalIssueCreateOneInput =
  createValidator<OrganisationalIssueCreateOneInput>(
    organisationalIssueCreateOneInputSchema
  );

export type OrganisationalIssueUpdateOneInput = {
  issueName?: string;
  issueDescription?: string;
  isArchived?: boolean;
};

export const organisationalIssueUpdateOneInputSchemaProps = {
  issueName: organisationalIssueIssueNameSchema,
  issueDescription: organisationalIssueIssueDescriptionSchema,
  isArchived: yup.boolean(),
};

export const organisationalIssueUpdateOneInputSchema = createSchema(
  organisationalIssueUpdateOneInputSchemaProps
);

export const validateOrganisationalIssueUpdateOneInput =
  createValidator<OrganisationalIssueUpdateOneInput>(
    organisationalIssueUpdateOneInputSchema
  );
