import * as yup from "yup";
import {
  createSchema,
  createValidator,
  passwordSchema,
  tenantNameSchema,
  userEmailSchema,
  userFirstNameSchema,
  userLastNameSchema,
} from "./utils";

export type Tenant = {
  tenantId: string;
  tenantName: string;
  isSecondFactorEnforced: boolean;
};

export type TenantCreateOneInput = {
  tenantId: string;
  tenantName: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  isSecondFactorEnforced?: boolean;
  shouldPopulateDemoData?: boolean;
};

export const tenantIdSchema = yup
  .string()
  .trim()
  .min(5)
  .max(35)
  .notOneOf(["auth", "public"])
  .matches(/^[a-z]+(_[a-z]+)*$/);

export const tenantCreateOneInputSchemaProps = {
  tenantId: tenantIdSchema.required(),
  tenantName: tenantNameSchema.required(),
  email: userEmailSchema.required(),
  firstName: userFirstNameSchema.required(),
  lastName: userLastNameSchema.required(),
  password: passwordSchema.required(),
  isSecondFactorEnforced: yup.boolean().default(false),
  shouldPopulateDemoData: yup.boolean().default(false),
};

export const tenantCreateOneInputSchema = createSchema(
  tenantCreateOneInputSchemaProps
);

export const validateTenantCreateOneInput =
  createValidator<TenantCreateOneInput>(tenantCreateOneInputSchema);

export type TenantUpdateOneInput = {
  tenantName?: string;
  isSecondFactorEnforced?: boolean;
};

export const tenantUpdateOneInputSchemaProps = {
  tenantName: tenantNameSchema,
  isSecondFactorEnforced: yup.boolean(),
};

export const tenantUpdateOneInputSchema = createSchema(
  tenantUpdateOneInputSchemaProps
);

export const validateTenantUpdateOneInput =
  createValidator<TenantUpdateOneInput>(tenantUpdateOneInputSchema);
