import * as yup from "yup";
import YupPassword from "yup-password";
import { ObjectShape } from "yup/lib/object";
import qs from "qs";

YupPassword(yup); // extend yup with password() method

export function parseQuery(query: string) {
  return qs.parse(query, {
    arrayLimit: 99999,
    strictNullHandling: true,
    ignoreQueryPrefix: true,
  }) as any;
}

export function stringifyQuery(query: any) {
  return qs.stringify(query, {
    arrayFormat: "repeat",
    strictNullHandling: true,
    addQueryPrefix: true,
  });
}

export function createSchema<T extends ObjectShape>(schemaProps: T) {
  return yup.object(schemaProps).noUnknown();
}

export function createValidator<
  T extends any,
  U extends yup.AnySchema = yup.AnySchema
>(schema: U): (input: T) => T {
  return function (object: T): T {
    return schema.validateSync(object);
  };
}

export const idSchema = yup.number().integer().positive();

export const validateId = createValidator<number>(idSchema);

// this is in utils to avoid circular dependency
export const tenantNameSchema = yup.string().trim().min(5).max(35);
export const userEmailSchema = yup.string().trim().lowercase().email();
export const validateUserEmail = createValidator<string>(userEmailSchema);
export const passwordSchema = yup.string().password();
export const userFirstNameSchema = yup.string().trim().min(1).max(35);
export const userLastNameSchema = yup.string().trim().min(1).max(35);
export const userSaltSchema = yup.string();

export type Pagination = {
  page: number;
  pageSize: number;
};

export const paginationSchemaProps = {
  page: yup.number().integer().min(1).default(1),
  pageSize: yup.number().integer().min(1).max(10000).default(100),
};

export const validatePagination = createValidator<Pagination>(
  createSchema(paginationSchemaProps)
);

export type Ordering = {
  orderBy: string;
  orderDirection: "ASC" | "DESC";
};

export const orderingSchemaProps = (
  fields: string[],
  defaultField: string,
  defaultDirection?: "ASC" | "DESC"
) => ({
  orderBy: yup.string().oneOf(fields).default(defaultField),
  orderDirection: yup
    .string()
    .uppercase()
    .oneOf(["ASC", "DESC"])
    .default(defaultDirection || "ASC"),
});
