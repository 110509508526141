import React from "react";
import { Link as RRDLink, LinkProps as RRDLinkProps } from "react-router-dom";
import { twMerge } from "tailwind-merge";

type Props = RRDLinkProps;

const Link: React.FC<Props> = ({ className, ...rest }) => {
  return (
    <RRDLink
      className={twMerge("text-primary hover:underline", className)}
      {...rest}
    />
  );
};

export default Link;
