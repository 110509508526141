import {
  mdiAlertCircleOutline,
  mdiAlienOutline,
  mdiArchive,
  mdiArchiveOff,
  mdiArrowRight,
  mdiBackburger,
  mdiCheck,
  mdiCheckCircleOutline,
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight,
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiClose,
  mdiDelete,
  mdiEmailOutline,
  mdiForwardburger,
  mdiInformationOutline,
  mdiLaptop,
  mdiLoading,
  mdiLogout,
  mdiPencil,
  mdiPlus,
  mdiTableAlert,
  mdiThumbsUpDown,
  mdiWeatherNight,
  mdiWhiteBalanceSunny,
} from "@mdi/js";
import { Icon } from "@mdi/react";
import { IconProps as MdiIconProps } from "@mdi/react/dist/IconProps";
import React from "react";
import { twMerge } from "tailwind-merge";

type IconProps = {
  className?: string;
  style?: React.CSSProperties;
  size?: number;
};

const createIcon =
  (path: string) =>
    ({ className, size = 5, style }: IconProps) =>
    (
      <svg
        className={twMerge(`h-${size} w-${size}`, className)}
        style={style}
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d={path} />
      </svg>
    );

export const AddIcon = createIcon(mdiPlus);
export const ArchiveIcon = createIcon(mdiArchive);
export const ArrowRightIcon = createIcon(mdiArrowRight);
export const CheckIcon = createIcon(mdiCheck);
export const CloseMenuIcon = createIcon(mdiBackburger);
export const CloseIcon = createIcon(mdiClose);
export const DarkModeIcon = createIcon(mdiWeatherNight);
export const DeleteIcon = createIcon(mdiDelete);
export const DropdownArrowIcon = createIcon(mdiChevronDown);
export const EditIcon = createIcon(mdiPencil);
export const EmptyTableIcon = createIcon(mdiTableAlert);
export const ErrorIcon = createIcon(mdiAlertCircleOutline);
export const InfoIcon = createIcon(mdiInformationOutline);
export const LightModeIcon = createIcon(mdiWhiteBalanceSunny);
export const LoadingIcon = createIcon(mdiLoading);
export const LogoutIcon = createIcon(mdiLogout);
export const NextIcon = createIcon(mdiChevronRight);
export const NextSkipIcon = createIcon(mdiChevronDoubleRight);
export const NotFoundIcon = createIcon(mdiAlienOutline);
export const OpenMenuIcon = createIcon(mdiForwardburger);
export const PrevIcon = createIcon(mdiChevronLeft);
export const PrevSkipIcon = createIcon(mdiChevronDoubleLeft);
export const RateMyShiftIcon = createIcon(mdiThumbsUpDown);
export const SuccessIcon = createIcon(mdiCheckCircleOutline);
export const SystemModeIcon = createIcon(mdiLaptop);
export const UnarchiveIcon = createIcon(mdiArchiveOff);

// new icons - TO DO migrate the above to this
type NewIconProps = Omit<MdiIconProps, "path">;

export const EmailSendIcon = ({
  size = 1,
  style = { display: "inline" },
  ...rest
}: NewIconProps) => (
  <Icon size={size} style={style} {...rest} path={mdiEmailOutline} />
);
