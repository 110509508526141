import { Card, Row, theme } from "antd";
import { trpc } from "lib/trpc";
import { useIsDarkTheme } from "lib/useDarkTheme";
import React from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import { InvitationForm } from "./InvitationForm";
import { LoginForms } from "./login-forms";
import { PasswordResetForms } from "./password-reset-forms";

export const Auth: React.FC = () => {
  const { token } = theme.useToken();
  const { refetch: refetchUser } = trpc.auth.currentUser.useQuery();
  const isDarkTheme = useIsDarkTheme();

  const navigate = useNavigate();
  const location = useLocation();

  const onSecondFactorSetUpRequired = () => {
    navigate("set-up-2fa");
  };

  const onSetPasswordSuccess = () => {
    navigate("login");
  };

  const onLoginSuccess = () => {
    refetchUser();
    const returnTo = (location.state as any)?.returnTo;
    navigate(returnTo || "/", { replace: true });
  };

  const element = useRoutes([
    {
      path: "invitation/:email/:token",
      element: <InvitationForm onSetPasswordSuccess={onSetPasswordSuccess} />,
    },
    {
      path: "login/*",
      element: (
        <LoginForms
          onLoginSuccess={onLoginSuccess}
          onSecondFactorSetUpRequired={onSecondFactorSetUpRequired}
        />
      ),
    },
    {
      path: "password-reset/*",
      element: (
        <PasswordResetForms onSetPasswordSuccess={onSetPasswordSuccess} />
      ),
    },
    {
      path: "*",
      element: <Navigate to="login" replace={true} />,
    },
  ]);

  const styles = {
    page: {
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: token.paddingMD,
      backgroundColor: token.colorPrimaryBg,
    },
    card: {
      width: "100%",
      maxWidth: "500px",
      height: "600px",
      maxHeight: "100%",
    },
    logoImg: {
      width: "100%",
      maxWidth: "400px",
      height: "auto",
    },
  };

  // centred, full width, max 600px
  return (
    <div style={styles.page}>
      <Card style={styles.card}>
        <Row justify="center">
          <img
            style={styles.logoImg}
            src={`/rms-logo${isDarkTheme ? "-dark-theme" : ""}.png`}
            alt="RateMyShift"
          />
        </Row>
        {element}
      </Card>
    </div>
  );
};
