import { App, Button, Form, Input, Typography } from "antd";
import { PasswordPolicyParagraph } from "components/auth";
import { trpc } from "lib/trpc";
import { zodFormRule } from "lib/zod-form-rule";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { getString } from "search-params-utils";
import { z } from "zod";
import { passwordSchema } from "../utils";

type Props = {
  onSetPasswordSuccess: () => void;
};

const formSchema = z.object({
  password: passwordSchema,
});

const rule = zodFormRule(formSchema);

export const ResetPasswordForm: React.FC<Props> = ({
  onSetPasswordSuccess,
}) => {
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const [searchParams] = useSearchParams();
  const email = getString(searchParams, "email");
  const token = getString(searchParams, "token");

  const { mutateAsync: resetPassword } = trpc.auth.password.reset.useMutation();

  const onFinish = async (values: z.infer<typeof formSchema>) => {
    try {
      await resetPassword({
        email: email!,
        token: token!,
        password: values.password,
      });

      message.success("Password reset successfully");

      onSetPasswordSuccess();
    } catch (error: any) {
      let errorText = error.message;
      if (errorText.includes("Invalid request")) {
        errorText =
          "Looks like you may have already reset your password. Please go to the login page.";
      } else if (errorText.includes("Invalid token")) {
        errorText =
          "Invalid token used - please make sure you are using the most recent reset link";
      } else if (errorText.includes("Token expired")) {
        errorText = "Token expired - please request a new password reset link";
      }
      message.error(errorText || "Something went wrong. Please try again.", 15);
    }
  };

  if (!email || !token) {
    return (
      <Typography.Paragraph>
        Please use the link in your email to reset your password.
      </Typography.Paragraph>
    );
  }

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      initialValues={{
        password: "",
        confirmPassword: "",
      }}
    >
      <Typography.Title level={2}>Password reset</Typography.Title>
      <Typography.Paragraph>
        Please set a new password for logging in
      </Typography.Paragraph>

      <PasswordPolicyParagraph />

      <Form.Item label="Password" name="password" rules={[rule]}>
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Confirm password"
        name="confirmPassword"
        rules={[
          {
            validator: async (_, value) => {
              const { password } = form.getFieldsValue();
              const isValid = password === value;
              if (!isValid) {
                throw new Error("Passwords do not match");
              }
            },
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          Reset password
        </Button>
      </Form.Item>
    </Form>
  );
};
