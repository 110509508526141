import React from "react";
import { twMerge } from "tailwind-merge";
import { EmptyTableIcon } from "./icons";
import LoadingSpinner from "./LoadingSpinner";

export type TableColumn<Row = any> = {
  header: React.ReactNode;
  field: keyof Row;
  render?: (value: any, row: Row, idx: number, rows: Row[]) => React.ReactNode;
  dataCellClassName?:
    | string
    | ((value: any, row: Row, idx: number, rows: Row[]) => string);
  headerCellClassName?: string;
};

type Props = React.ComponentProps<"table"> & {
  wrapperDivRef?: React.RefObject<HTMLDivElement>;
  wrapperDivClassName?: string;
  error?: string;
  loading?: boolean;
  rows?: any[];
  rowClassName?: string | ((row: any, idx: number, rows: any[]) => string);
  columns: TableColumn[];
};

const Table: React.FC<Props> = ({
  error,
  rows = [],
  columns,
  loading,
  className,
  wrapperDivRef,
  wrapperDivClassName,
  rowClassName,
  ...rest
}) => {
  return (
    <div className={twMerge("overflow-x-auto", wrapperDivClassName)}>
      <table
        className={twMerge(
          "table table-auto border-collapse w-full",
          className
        )}
        {...rest}
      >
        <thead>
          <tr>
            {columns.map(({ header, headerCellClassName }, idx) => (
              <th
                key={idx}
                className={twMerge("normal-case text-sm", headerCellClassName)}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={columns.length}>
                <div className="flex flex-row justify-center">
                  <LoadingSpinner />
                </div>
              </td>
            </tr>
          ) : error ? (
            <tr>
              <td
                colSpan={columns.length}
                className="text-center bg-error text-error-content"
              >
                {error}
              </td>
            </tr>
          ) : rows.length === 0 ? (
            <tr>
              <td colSpan={columns.length}>
                <div className="flex flex-col justify-center items-center">
                  <EmptyTableIcon size={7} />
                  <div className="text-center text-sm">No data to show</div>
                </div>
              </td>
            </tr>
          ) : (
            rows.map((row, idx) => {
              let thisRowClassName = rowClassName;
              if (thisRowClassName && typeof thisRowClassName !== "string") {
                thisRowClassName = thisRowClassName(row, idx, rows);
              }
              thisRowClassName = twMerge(thisRowClassName);

              return (
                <tr key={idx} className={thisRowClassName}>
                  {columns.map(({ field, render, dataCellClassName }, idx) => {
                    if (
                      dataCellClassName &&
                      typeof dataCellClassName !== "string"
                    ) {
                      dataCellClassName = dataCellClassName(
                        row[field],
                        row,
                        idx,
                        rows
                      );
                    }

                    return (
                      <td
                        key={idx}
                        className={twMerge("bg-inherit", dataCellClassName)}
                      >
                        {render ? (
                          render(row[field], row, idx, rows)
                        ) : row[field] !== undefined && row[field] !== null ? (
                          row[field]
                        ) : (
                          <>&ndash;</>
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
