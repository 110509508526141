import { App, Button, Form, Radio, Space, Typography } from "antd";
import { trpc } from "lib/trpc";
import React from "react";
import { useLocation } from "react-router-dom";

type Props = {
  onCodeSendSuccess: () => void;
  onNoMethodsAvailable: () => void;
};

type FormValues = {
  method: "email" | "sms";
};

export const RequestCodeForm: React.FC<Props> = ({
  onCodeSendSuccess,
  onNoMethodsAvailable,
}) => {
  const location = useLocation();

  const { mutateAsync: sendCode } =
    trpc.auth.login.sendSecondFactorCode.useMutation();

  const { message } = App.useApp();

  const methods: ("email" | "sms")[] | undefined = location.state?.methods;

  if (!methods) {
    onNoMethodsAvailable();
    return null;
  }

  const canSms = methods.includes("sms");

  const onFinish = async (values: FormValues) => {
    try {
      await sendCode(values.method);
      message.success("Code sent!");
      onCodeSendSuccess();
    } catch (error: any) {
      message.error(error.message || "Something went wrong. Please try again.");
    }
  };

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      initialValues={{
        method: canSms ? "sms" : "email",
      }}
    >
      <Typography.Title level={3}>Send a code to verify</Typography.Title>

      <Form.Item
        name="method"
        label="Choose how you would like to receive your code"
      >
        <Radio.Group size="large">
          <Space direction="vertical">
            <Radio value="sms" disabled={!canSms}>
              SMS{!canSms ? " (not set up)" : null}
            </Radio>
            <Radio value="email">Send code via email</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          Send code
        </Button>
      </Form.Item>
    </Form>
  );
};
