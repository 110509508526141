import { App, Button, Form, Input, Typography, theme } from "antd";
import { trpc } from "lib/trpc";
import { zodFormRule } from "lib/zod-form-rule";
import React from "react";
import { Link } from "react-router-dom";
import { z } from "zod";
import { passwordSchema } from "../utils";

type Props = {
  onSecondFactorRequired: (methods: string[]) => void;
  onLoginSuccess: () => void;
};

type FormValues = {
  email: string;
  password: string;
};

const formSchema = z.object({
  email: z.string().email(),
  password: passwordSchema,
});

const rule = zodFormRule(formSchema);

export const LoginEmailPasswordForm: React.FC<Props> = ({
  onSecondFactorRequired,
  onLoginSuccess,
}) => {
  const { message } = App.useApp();
  const { token } = theme.useToken();

  const { mutateAsync: login } = trpc.auth.login.password.useMutation();

  const onFinish = async (values: FormValues) => {
    try {
      const { isSecondFactorRequired, secondFactorMethods } = await login(
        values
      );
      if (isSecondFactorRequired) {
        onSecondFactorRequired(secondFactorMethods!);
        return;
      }

      onLoginSuccess();
    } catch (error: any) {
      message.error(error.message || "Something went wrong. Please try again.");
    }
  };

  const styles: Record<string, React.CSSProperties> = {
    forgotYourPassword: {
      textAlign: "center",
      fontSize: token.fontSizeLG,
    },
    forgotYourPasswordLink: {
      fontWeight: "bold",
    },
  };

  return (
    <Form layout="vertical" onFinish={onFinish}>
      <Typography.Title level={2}>Welcome</Typography.Title>
      <Typography.Paragraph>Please login to begin</Typography.Paragraph>

      <Form.Item label="Email" name="email" rules={[rule]}>
        <Input />
      </Form.Item>
      <Form.Item label="Password" name="password" rules={[rule]}>
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          Log in
        </Button>
      </Form.Item>

      <Typography.Paragraph style={styles.forgotYourPassword}>
        Forgot your password?{" "}
        <Link to={`../../password-reset`} style={styles.forgotYourPasswordLink}>
          Click here to reset it
        </Link>
      </Typography.Paragraph>
    </Form>
  );
};
