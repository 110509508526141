import { DatePicker } from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import React from "react";

export type DateRangePickerProps = {
  useDefaultPresets?: boolean;
} & RangePickerProps;

const DEFAULT_PRESETS: RangePickerProps["presets"] = [
  {
    label: "This Week",
    value: [dayjs().startOf("week"), dayjs().endOf("day")],
  },
  {
    label: "This Month",
    value: [dayjs().startOf("month"), dayjs().endOf("day")],
  },
  {
    label: "This Year",
    value: [dayjs().startOf("year"), dayjs().endOf("day")],
  },
  {
    label: "Last Week",
    value: [
      dayjs().subtract(1, "week").startOf("week"),
      dayjs().subtract(1, "week").endOf("week"),
    ],
  },
  {
    label: "Last Month",
    value: [
      dayjs().subtract(1, "month").startOf("month"),
      dayjs().subtract(1, "month").endOf("month"),
    ],
  },
  {
    label: "Last Year",
    value: [
      dayjs().subtract(1, "year").startOf("year"),
      dayjs().subtract(1, "year").endOf("year"),
    ],
  },
  {
    label: "Past 7 Days",
    value: [dayjs().subtract(7, "day"), dayjs().endOf("day")],
  },
  {
    label: "Past 30 Days",
    value: [dayjs().subtract(30, "day"), dayjs().endOf("day")],
  },
  {
    label: "Past Year",
    value: [dayjs().subtract(1, "year").startOf("year"), dayjs().endOf("day")],
  },
];

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  useDefaultPresets: defaultPresets,
  ...props
}) => {
  return (
    <DatePicker.RangePicker
      format={(d) => d.toDate().toLocaleDateString()}
      presets={props.presets || defaultPresets ? DEFAULT_PRESETS : undefined}
      {...props}
    />
  );
};
