import React, { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  className?: string;
};

const FormSection: React.FC<ComponentProps<"section">> = ({
  children,
  className,
}) => {
  return <section className={twMerge("w-full", className)}>{children}</section>;
};

export default FormSection;
