import React, { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

export type TextInputProps = ComponentProps<"input"> & {
  color?:
    | "neutral"
    | "primary"
    | "secondary"
    | "accent"
    | "info"
    | "success"
    | "warning"
    | "error";
  inputSize?: "lg" | "md" | "sm" | "xs";
};

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    { children, color = "neutral", inputSize: size = "md", className, ...rest },
    ref
  ) => {
    const colorClass = color !== "neutral" ? `text-${color}` : "";
    const sizeClass = size !== "md" ? `select-${size}` : "";

    return (
      <input
        className={twMerge(
          "input input-bordered input-primary w-full",
          colorClass,
          sizeClass,
          className
        )}
        ref={ref}
        {...rest}
      />
    );
  }
);

export default TextInput;
