import React from "react";
import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import { RequestResetForm } from "./RequestResetForm";
import { RequestResetSuccess } from "./RequestResetSuccess";
import { ResetPasswordForm } from "./ResetPasswordForm";

type Props = {
  onSetPasswordSuccess: () => void;
};

export const PasswordResetForms: React.FC<Props> = ({
  onSetPasswordSuccess,
}) => {
  const navigate = useNavigate();

  function onRequestResetSuccess(email: string) {
    const searchParams = new URLSearchParams({
      email,
    });
    navigate(`request/success?${searchParams.toString()}`);
  }

  const element = useRoutes([
    {
      path: "request",
      element: (
        <RequestResetForm onRequestResetSuccess={onRequestResetSuccess} />
      ),
    },
    {
      path: "request/success",
      element: <RequestResetSuccess />,
    },
    {
      path: "reset",
      element: (
        <ResetPasswordForm onSetPasswordSuccess={onSetPasswordSuccess} />
      ),
    },
    {
      path: "*",
      element: <Navigate to="request" replace={true} />,
    },
  ]);

  return element;
};
