import { AddMobileNumber } from "add-mobile-number";
import { Auth } from "auth";
import { Splash } from "components";
import { trpc } from "lib/trpc";
import { useShouldRequestMobileNumber } from "lib/use-should-request-mobile-number";
import useScreenSize from "lib/useScreenSize";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { RequestNotificationsBanner } from "RequestNotificationsBanner";
import Sidebar from "sidebar";
import { twMerge } from "tailwind-merge";
import Pages from "./pages";

const App: React.FC = () => {
  const { isLgUp } = useScreenSize();
  const [sidebarIsVisible, setSidebarIsVisible] = useState(isLgUp);
  const location = useLocation();
  const navigate = useNavigate();
  const { data: user, isLoading: userIsLoading } =
    trpc.auth.currentUser.useQuery();
  const { shouldRequestMobileNumber } = useShouldRequestMobileNumber();

  // send to /auth if not logged in, or away from /auth if logged in
  useEffect(() => {
    const shouldRedirectToAuth =
      user === null && !location.pathname.startsWith("/auth");
    const shouldRedirectToApp = user && location.pathname.startsWith("/auth");

    if (shouldRedirectToAuth) {
      const returnTo = location.pathname + location.hash + location.search;
      navigate("/auth", {
        state: {
          returnTo,
        },
        replace: true,
      });
      return;
    }

    if (shouldRedirectToApp) {
      navigate("/", { replace: true });
      return;
    }
  }, [user]);

  useEffect(() => {
    if (shouldRequestMobileNumber) {
      navigate("/add-mobile-number", { replace: true });
    }
  }, [shouldRequestMobileNumber]);

  // change sidebar visibility on screen size change
  useEffect(() => {
    setSidebarIsVisible(isLgUp);
  }, [isLgUp, setSidebarIsVisible]);

  const element = useRoutes([
    {
      path: "/auth/*",
      element: <Auth />,
    },
    {
      path: "/add-mobile-number/*",
      element: <AddMobileNumber />,
    },
    {
      path: "*",
      element: (
        <>
          <Sidebar
            isVisible={sidebarIsVisible}
            setIsVisible={setSidebarIsVisible}
          />
          <div
            className={twMerge(
              "transition-all min-h-screen",
              !sidebarIsVisible ? "lg:ml-0" : "lg:ml-80",
              "bg-base-200"
            )}
          >
            <RequestNotificationsBanner />
            <Pages sidebarIsVisible={sidebarIsVisible} />
          </div>
        </>
      ),
    },
  ]);

  const shouldShowSplash =
    userIsLoading || (user === null && !location.pathname.startsWith("/auth"));

  if (shouldShowSplash) return <Splash />;

  return <div className="min-h-screen">{element}</div>;
};

export default App;
