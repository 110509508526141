import { RuleObject } from "antd/es/form";
import z from "zod";

export function zodFormRule(schema: z.AnyZodObject) {
  const validator: RuleObject["validator"] = async (rule, value) => {
    try {
      await schema.shape[
        // @ts-ignore - AntD doesn't have a type for this but it does exist
        rule.field
      ].parseAsync(value);
    } catch (err: any) {
      const message = err.issues.map((issue: any) => issue.message).join(", ");
      throw new Error(message);
    }
  };

  return {
    validator,
  };
}
