import { App, Button, Form, Input, Typography } from "antd";
import { trpc } from "lib/trpc";
import { zodFormRule } from "lib/zod-form-rule";
import React from "react";
import { z } from "zod";
import { secondFactorCodeSchema } from "../auth/utils";

type Props = {
  onVerifySuccess: () => void;
};

const formSchema = z.object({
  code: secondFactorCodeSchema,
});

const rule = zodFormRule(formSchema);

export const VerifyMobileNumberForm: React.FC<Props> = ({
  onVerifySuccess,
}) => {
  const { mutateAsync: submitCode } =
    trpc.auth.mobileNumber.verify.useMutation();

  const { message } = App.useApp();

  const onFinish = async (values: z.infer<typeof formSchema>) => {
    try {
      await submitCode(values.code);
      message.success("Mobile number verified!");
      onVerifySuccess();
    } catch (error: any) {
      message.error(error.message || "Something went wrong. Please try again.");
    }
  };

  return (
    <Form layout="vertical" onFinish={onFinish} autoComplete="off">
      <Typography.Title level={3}>Verify</Typography.Title>
      <Typography.Paragraph>
        Enter the code we have sent to verify your mobile number
      </Typography.Paragraph>

      <Form.Item name="code" label="Code" required rules={[rule]}>
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          Verify
        </Button>
      </Form.Item>
    </Form>
  );
};
