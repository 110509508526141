import { useSearchParams } from "react-router-dom";
import { parseQuery, stringifyQuery } from "shared";

export function useQuery<T extends object = any>() {
  const [searchParams, setSearchParams] = useSearchParams();

  const query: T = parseQuery(searchParams.toString());

  function setQuery(query: Partial<T>) {
    const queryString = stringifyQuery(query);
    setSearchParams(queryString);
  }

  return { query, setQuery } as const;
}
