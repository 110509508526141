import { z } from "zod";

export const passwordSchema = z
  .string()
  .min(8)
  .max(100)
  .regex(/^(?=.*[0-9])/, "Must contain at least one number")
  .regex(/^(?=.*[a-z])/, "Must contain at least one lowercase letter")
  .regex(/^(?=.*[A-Z])/, "Must contain at least one uppercase letter")
  .regex(
    /^(?=.*[!@#$%^&*(){}\[\]\/?|<>\-_=+~`])/,
    "Must contain at least one special character (symbol): !@#$%^&*()[]{}/?|<>-_=+~`"
  );

export const secondFactorCodeSchema = z
  .string()
  .regex(/^\d{6}$/, { message: "Code must be 6 digits" });
