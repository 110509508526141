import { Typography, theme } from "antd";
import useCurrentUser from "lib/use-current-user";
import React from "react";
import LogoutButton from "./LogoutButton";
import ThemeSelectPopupMenu from "./ThemeSelectPopupMenu";

const SidebarFooter: React.FC = ({}) => {
  const { user } = useCurrentUser();

  const { token } = theme.useToken();

  const styles = {
    tenantText: {
      color: token.colorWhite,
      size: token.fontSizeSM,
    },
  };

  return (
    <div>
      <div
        id="sidebar-footer"
        className="flex flex-row space-x-2 text-primary-content"
      >
        <div className="flex-grow">
          <div>
            <div>
              {user.firstName} {user.lastName}
            </div>
            <div className="text-xs">
              <b>{user.email}</b>
            </div>
          </div>
        </div>
        <ThemeSelectPopupMenu />
        <LogoutButton />
      </div>
      <Typography.Text style={styles.tenantText}>
        {user.tenantName}
      </Typography.Text>
    </div>
  );
};

export default SidebarFooter;
