import { Splash } from "components";
import useCurrentUser, { CurrentUser } from "lib/use-current-user";
import React, { Suspense, useEffect } from "react";
import {
  RouteObject,
  useLocation,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import NotFound from "./NotFound";

// lazy load pages
const RateMyShift = React.lazy(() => import("./rate-my-shift"));
const TeamManager = React.lazy(() => import("./team-manager"));
const SeniorManager = React.lazy(() => import("./senior-manager"));
const ShiftFollowUp = React.lazy(
  () => import("./team-manager/shift-follow-up")
);
const OrganisationOverview = React.lazy(
  () => import("./organisation-overview")
);
const AdminPage = React.lazy(() => import("./admin"));

type Props = {
  sidebarIsVisible: boolean;
};

function getUserRoutes(user: CurrentUser) {
  const routes: RouteObject[] = [
    {
      path: "/rate-my-shift",
      element: <RateMyShift />,
    },
  ];

  if (user.managerOfTeamIds.length) {
    routes.push(
      {
        path: "/team-manager/*",
        element: <TeamManager />,
      },
      {
        path: "/team-manager/shift-follow-up/*",
        element: <ShiftFollowUp />,
      }
    );
  }

  if (user.seniorManagerOfTeamIds.length) {
    routes.push({
      path: "/senior-manager/*",
      element: <SeniorManager />,
    });
  }

  if (user.isAdmin) {
    routes.push({
      path: "/admin/*",
      element: <AdminPage />,
    });
  }
  return routes;
}

const Pages: React.FC<Props> = ({ sidebarIsVisible }) => {
  const { user } = useCurrentUser();
  const location = useLocation();
  const navigate = useNavigate();

  const userRoutes = React.useMemo(() => getUserRoutes(user), [user]);

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/rate-my-shift", { replace: true });
    }
  }, [location.pathname]);

  const element = useRoutes([
    ...userRoutes,
    {
      path: "/",
      element: <Splash />, // until we navigate to a page, show the splash
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  return (
    <Suspense fallback={<Splash />}>{element}</Suspense>
  );
};

export default Pages;
