import { Result } from "antd";
import { useQuery } from "lib/useQuery";
import React from "react";

export const RequestResetSuccess: React.FC = () => {
  const { query } = useQuery<{ email: string }>();
  const { email } = query;

  return (
    <Result
      status="success"
      title="Password reset email sent"
      subTitle={`If we have an account with the email ${email}, we've sent a password reset link to it.`}
    />
  );
};
