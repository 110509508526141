import { App, Button, Form, Input, Typography } from "antd";
import { trpc } from "lib/trpc";
import { zodFormRule } from "lib/zod-form-rule";
import React from "react";
import { z } from "zod";

type Props = {
  onRequestResetSuccess: (email: string) => void;
};

const formSchema = z.object({
  email: z.string().email(),
});

const rule = zodFormRule(formSchema);

export const RequestResetForm: React.FC<Props> = ({
  onRequestResetSuccess,
}) => {
  const { message } = App.useApp();

  const { mutateAsync: requestPasswordReset } =
    trpc.auth.password.requestReset.useMutation();

  const onFinish = async (values: z.infer<typeof formSchema>) => {
    try {
      await requestPasswordReset(values.email);
      onRequestResetSuccess(values.email);
    } catch (error: any) {
      let errorText = error.message;

      message.error(errorText || "Something went wrong. Please try again.");
    }
  };

  return (
    <Form layout="vertical" onFinish={onFinish}>
      <Typography.Title level={2}>Password reset</Typography.Title>
      <Typography.Paragraph>
        Enter your email to receive a link to reset your password
      </Typography.Paragraph>
      <Form.Item label="Email" name="email" rules={[rule]}>
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          Request password reset
        </Button>
      </Form.Item>
    </Form>
  );
};
