import { Card, Row, theme } from "antd";
import { trpc } from "lib/trpc";
import { useShouldRequestMobileNumber } from "lib/use-should-request-mobile-number";
import { useIsDarkTheme } from "lib/useDarkTheme";
import React from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import { EnterMobileNumberForm } from "./EnterMobileNumberForm";
import { VerifyMobileNumberForm } from "./VerifyMobileNumberForm";

export const AddMobileNumber: React.FC = () => {
  const { token } = theme.useToken();
  const { refetch: refetchUser } = trpc.auth.currentUser.useQuery();

  const { dismissMobileNumberRequest } = useShouldRequestMobileNumber();

  const isDarkTheme = useIsDarkTheme();

  const navigate = useNavigate();
  const location = useLocation();

  const onAddSuccess = () => {
    navigate("verify");
  };

  const onVerifySuccess = () => {
    refetchUser();
    const returnTo = (location.state as any)?.returnTo;
    navigate(returnTo || "/", { replace: true });
  };

  const onDismiss = () => {
    dismissMobileNumberRequest();
    const returnTo = (location.state as any)?.returnTo;
    navigate(returnTo || "/", { replace: true });
  };

  const element = useRoutes([
    {
      path: "enter",
      element: (
        <EnterMobileNumberForm
          onAddSuccess={onAddSuccess}
          onDismiss={onDismiss}
        />
      ),
    },
    {
      path: "verify",
      element: <VerifyMobileNumberForm onVerifySuccess={onVerifySuccess} />,
    },
    {
      path: "*",
      element: <Navigate to="enter" replace={true} />,
    },
  ]);

  const styles = {
    page: {
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: token.paddingMD,
      backgroundColor: token.colorPrimaryBg,
    },
    card: {
      width: "100%",
      maxWidth: "500px",
      height: "600px",
      maxHeight: "100%",
    },
    logoImg: {
      width: "100%",
      maxWidth: "400px",
      height: "auto",
    },
  };

  // centred, full width, max 600px
  return (
    <div style={styles.page}>
      <Card style={styles.card}>
        <Row justify="center">
          <img
            style={styles.logoImg}
            src={`/rms-logo${isDarkTheme ? "-dark-theme" : ""}.png`}
            alt="RateMyShift"
          />
        </Row>
        {element}
      </Card>
    </div>
  );
};
