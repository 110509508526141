import React, { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

// select	Component	For <select> element
// select-bordered	Modifier	Adds border to select
// select-ghost	Modifier	Adds ghost style to select
// select-primary	Modifier	Adds `primary` color to select
// select-secondary	Modifier	Adds `secondary` color to select
// select-accent	Modifier	Adds `accent` color to select
// select-info	Modifier	Adds `info` color to select
// select-success	Modifier	Adds `success` color to select
// select-warning	Modifier	Adds `warning` color to select
// select-error	Modifier	Adds `error` color to select
// select-lg	Responsive	Large size for select
// select-md	Responsive	Medium (default) size for select
// select-sm	Responsive	Small size for select
// select-xs	Responsive	Extra small size for select

type Props = ComponentProps<"select"> & {
  color?:
    | "neutral"
    | "primary"
    | "secondary"
    | "accent"
    | "info"
    | "success"
    | "warning"
    | "error";
  selectSize?: "lg" | "md" | "sm" | "xs";
};

const Select: React.FC<Props> = ({
  children,
  color = "neutral",
  selectSize: size = "md",
  className,
  ...rest
}) => {
  const colorClass = color !== "neutral" ? `text-${color}` : "";
  const sizeClass = size !== "md" ? `select-${size}` : "";

  return (
    <select
      className={twMerge(
        "select select-bordered select-primary",
        colorClass,
        sizeClass,
        className
      )}
      {...rest}
    >
      {children}
    </select>
  );
};

export default Select;
