import LoadingSpinner from "./LoadingSpinner";
import React from "react";

const Splash: React.FC = () => (
  <div className="h-screen flex justify-center items-center">
    <LoadingSpinner className="text-5xl text-secondary" />
  </div>
);

export default Splash;
