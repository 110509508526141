import React from "react";
import { twMerge } from "tailwind-merge";
import { LoadingIcon } from "./icons";

type Props = {
  className?: string;
};

const LoadingSpinner: React.FC<Props> = ({ className }) => {
  return (
    <LoadingIcon
      style={{ height: 60 }}
      className={twMerge("animate-spin", className)}
    />
  );
};

export default LoadingSpinner;
